const isProduction = process.env.NODE_ENV === 'production';

// ##### EVENTS #####

export const EVENT_PURCHASE = 'purchase';
export const GA_EVENT = 'ga-event';
export const ROUTE_CHANGE = 'ga-pageview';

// ##### CATEGORIES #####

// CATEGORIES-COMMONS
export const LOGIN_CATEGORY = 'Login';

// CATEGORIES-QUOTATIONS
export const QUOTATION_CATEGORY = 'Cotação';

// CATEGORIES-LABELS
export const LABELS_CATEGORY = 'Etiquetas';

// ORDER-CATEGORY
export const ORDER_CATEGORY = 'Pedido';

// ##### ACTIONS #####

// ACTIONS-COMMONS
export const INFINITE_SCROLL_ACTION = 'InfiniteScroll';
export const LOGIN_ACTION = 'Login efetuado';
export const ORDER_LIST_ACTION = 'Ordenar Listagem';
export const PRINT_ACTION = 'Imprimir';
export const REGISTER_ACTION = 'Criar novo registro';
export const SIGNED_CONSIGNEE_LETTER_ACTION = 'Carta consignatória assinada';

// ACTIONS-SHIPPING CARRIERS
export const SELECTED_SHIPPING_CARRIER_ACTION = 'Transportadora selecionada';
export const SENT_SHIPPING_DATA_ACTION = 'Dados enviados para a transportadora';

// ACTIONS-TERMS
export const SIGNED_EXEMPTION_TERM_ACTION = 'Termo de isenção assinado';

// ACTIONS-INVOICES
export const EMITTED_INVOICE_ACTION = 'Fatura emitida';
export const VALIDATED_INVOICE_ACTION = 'Fatura validada';

// ACTIONS-ORDERS
export const SENT_INVOICE = 'Nota fiscal enviada';
export const FINISH_ORDER_ACTION = 'Finalizar pedido';
export const FINISH_FIRST_ORDER_ACTION = 'Finalizar primeiro pedido'
export const FINISHED_ORDER_ACTION = 'Pedido concluído';
export const MARK_ORDER_ACTION = 'Marcar pedido';
export const UNMARK_ORDER_ACTION = 'Remover pedido';

// ACTIONS-QUOTATIONS
export const CHARGE_READY_ACTION = 'Carga pronta';
export const CREATED_QUOTATION_ACTION = 'Cotação criada';
export const MEASURE_TYPE_UPDATED = 'Tipo de medida atualizado';
export const NEW_QUOTATION_ACTION = 'Nova cotação';

// ACTIONS-LABELS
export const EMIT_LABELS_ACTION = 'Emitir etiquetas';
export const OPEN_LABEL_ACTION = 'Abrir etiqueta';
export const PRINT_LABELS_ACTION = 'Imprimir etiquetas';

// ##### LABELS #####

// LABELS-COMMONS
export const BLANK_PAGE_LABEL = 'Tela em branco';
export const BRAND = 'Central do Frete';
export const FLOAT_BUTTON_LABEL = 'Botão flutuante';
export const LIST_LABEL = 'Listagem';
export const PAGE_LABEL = 'Página';
export const PRINT_LABEL = 'Impressão';
export const ORDER_LOG = 'Log do pedido';
export const FOLLOW_ORDER = 'Acompanhar pedido';

// LABELS-ORDERS
export const NO_ORDERS_LABEL = 'Sem pedidos';
export const SELECTED_ORDERS_LABEL = 'Pedidos selecionados';

export const pushToDataLayer = data => isProduction && (window.dataLayer || []).push({ ...data });

export const registerClick = (data, event = GA_EVENT) => pushToDataLayer({ ...data, event });
