import * as Yup from 'yup';

export const requiredValidation = (validation, message = 'Campo obrigatório') =>
  validation.required(message);

/**
 * Validations for phone numbers
 *
 * Empty string (^$) OR (|)
 * DDD from 11-99 ([1-9]{2})
 * Mobile: starts with 9 followed by 8 digits (9[0-9]{8})
 * Landline: starts with 3-7 followed by 7 digits ([3-7][0-9]{7})
 */
export const phoneValidation = Yup.string().matches(
  /^$|^([1-9]{2})(9[0-9]{8}|[3-7][0-9]{7})$/,
  'Número de telefone inválido, verifique se o DDD ou o 9° digito foram informados corretamente'
);

export const documentValidation = Yup.string().test(
  'checkDocument',
  'Informe um documento válido',
  document => {
    const cleanDocument = document ? document.replace(/[./-]/gi, '') : '';
    const length = String(cleanDocument).length;
    return length === 0 || length === 14 || length === 11;
  }
);

export const cpfValidation = Yup.string()
  .min(11, 'Informe um CPF válido')
  .max(11, 'Informe um CPF válido');

export const cnpjValidation = Yup.string()
  .min(14, 'Informe um CNPJ válido')
  .max(14, 'Informe um CNPJ válido');

export const emailValidation = Yup.string().email('Informe um email válido');
